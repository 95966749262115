<footer>
  <div class="app-container-sm footer-container">
    <div class="block w-50">
      <a routerLink="/">
        <mat-icon class="logo" svgIcon="icon-logo"></mat-icon>
      </a>

      <h6>© GG | All rights reserved</h6>

      <div class="socials">
        <a [href]="socialLinks.Twitter" target="_blank" rel="nofollow noopener noreferrer">
          <mat-icon svgIcon="icon-twitter"></mat-icon>
        </a>

        <a [href]="socialLinks.Telegram" target="_blank" rel="nofollow noopener noreferrer">
          <mat-icon svgIcon="icon-telegram"></mat-icon>
        </a>
      </div>
    </div>

    <div class="block w-25 link-block">
      <h5>Links</h5>

      <div class="navigation">
        @for(item of navigationItems; track item.label) {
          @if(item.type === 'routerLink') {
            <a class="nav-link" [routerLink]="item.url">
              {{ item.label }}
            </a>
          } @else {
            <a class="nav-link" [href]="item.url" [attr.target]="item.target" [attr.rel]="item.rel">
              {{ item.label }}
            </a>
          }
        }
      </div>
    </div>

    <div class="block w-25 link-block">
      <h5>Funds</h5>
      <div class="navigation">
        <a [href]="comingSoonUrl" target="_blank">Fund-1</a>
        <a [href]="comingSoonUrl" target="_blank">Fund-2</a>
        <a [href]="comingSoonUrl" target="_blank">Fund-3</a>
      </div>
    </div>

    <div class="mobile-links">
      <mat-accordion multi="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Links </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="navigation">
            @for(item of navigationItems; track item.label) { @if(item.type === 'routerLink') {
            <a class="nav-link" [routerLink]="item.url">
              {{ item.label }}
            </a>
            } @else {
            <a class="nav-link" [href]="item.url" [attr.target]="item.target" [attr.rel]="item.rel">
              {{ item.label }}
            </a>
            } }
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Funds </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="navigation">
            <a [href]="comingSoonUrl" target="_blank">Fund-1</a>
            <a [href]="comingSoonUrl" target="_blank">Fund-2</a>
            <a [href]="comingSoonUrl" target="_blank">Fund-3</a>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="app-container-sm">
    <div class="dev-info">
      <p>Developed at Blocksplit ETH Belgrade</p>
      <div class="icons">
        <div class="line mobile-line"></div>
        <mat-icon class="blocksplit" svgIcon="icon-blocksplit"></mat-icon>
        <div class="line"></div>
        <mat-icon class="eth-belgrad" svgIcon="icon-eth-belgrad"></mat-icon>
      </div>

    </div>
  </div>
</footer>
<div class="policy">© GG | All rights reserved</div>
