import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { headerMobileAnimation, NAVIGATION_ITEMS, NavigationItem } from '../../constants';
import { environment } from "@env/environment";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, MatIconModule, NgClass],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: headerMobileAnimation,
})
export class HeaderComponent {

  navigationItems: NavigationItem[] = NAVIGATION_ITEMS;
  readonly dashBoardUrl = environment.dappUrl;

  line1State: string = 'initial';
  line2State: string = 'initial';
  isMenuOpen: boolean = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.line1State = this.line1State === 'initial' ? 'rotated1' : 'initial';
    this.line2State = this.line2State === 'initial' ? 'rotated2' : 'initial';

    if (this.isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
}
