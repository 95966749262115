<header class="header">
  <div class="app-container-sm header__container">
    <a class="logo" routerLink="/">
      <mat-icon svgIcon="icon-logo"></mat-icon>
    </a>

    <div class="header__container--navigation">
      @for(item of navigationItems; track item.label) {
        @if(item.type === 'routerLink') {
          <a class="nav-link" [routerLink]="item.url">
            {{ item.label }}
          </a>
        } @else {
          <a class="nav-link" [href]="item.url" [attr.target]="item.target" [attr.rel]="item.rel">
            {{ item.label }}
          </a>
        }
      }
      <div class="line"></div>
      <a class="app-button" [href]="dashBoardUrl">Launch App</a>
    </div>

    <div class="header__container--mob-navigation">
      <button (click)="toggleMenu()">
        <div [@lineState]="line1State" class="nav-line"></div>
        <div [@lineState]="line2State" class="nav-line"></div>
      </button>
    </div>
  </div>
</header>

@if(isMenuOpen){
  <div
    class="mobile-nav-content"
    [ngClass]="{
      'slide-in': isMenuOpen,
      'slide-out': !isMenuOpen
    }"
  >
    @for(item of navigationItems; track item.label) {
      @if(item.type === 'routerLink') {
        <a class="nav-link" (click)="toggleMenu()" [routerLink]="item.url">
          {{ item.label }}
        </a>
      } @else {
        <a class="nav-link" (click)="toggleMenu()" [href]="item.url" [attr.target]="item.target" [attr.rel]="item.rel">
          {{ item.label }}
        </a>
      }
    }
    <a class="dashboard-button" (click)="toggleMenu()" [href]="dashBoardUrl">Launch App</a>
  </div>
}
