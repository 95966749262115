import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

export const SVG_ICONS: { [key: string]: string } = {
  'icon-logo': 'assets/icons/icon-logo.svg',
  'icon-twitter': 'assets/icons/icon-twitter.svg',
  'icon-discord': 'assets/icons/icon-discord.svg',
  'icon-telegram': 'assets/icons/icon-telegram.svg',
  'icon-tiktok': 'assets/icons/icon-tiktok.svg',
  'icon-no-data': 'assets/icons/icon-no-data.svg',
  'icon-service-logo': 'assets/icons/icon-service-logo.svg',
  'icon-opportunities': 'assets/icons/icon-opportunities.svg',
  'icon-growth': 'assets/icons/icon-growth.svg',
  'icon-research': 'assets/icons/icon-research.svg',
  'icon-tokenomics': 'assets/icons/icon-tokenomics.svg',
  'icon-eth-belgrad': 'assets/icons/icon-eth-belgrad.svg',
  'icon-blocksplit': 'assets/icons/icon-blocksplit.svg',
};

@Injectable({
  providedIn: 'root',
})
export class SvgRegisterService {
  constructor(private readonly iconRegistry: MatIconRegistry, private readonly sanitizer: DomSanitizer) {}

  public registerSvg(): void {
    Object.keys(SVG_ICONS).forEach((key) => {
      this.iconRegistry.addSvgIcon(key, this.sanitizer.bypassSecurityTrustResourceUrl(SVG_ICONS[key]));
    });
  }
}
