import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import {MatExpansionModule} from '@angular/material/expansion';
import { NAVIGATION_ITEMS, NavigationItem } from 'src/app/constants/header-external-links.constant';
import { SocialLinks } from "../../constants";
import { environment } from "@env/environment";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatIconModule, RouterLink, MatExpansionModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  readonly navigationItems: NavigationItem[] = NAVIGATION_ITEMS;
  readonly socialLinks = SocialLinks;

  // TODO: Add the correct URL
  readonly comingSoonUrl = environment.dappUrl;
}
