import { Routes } from '@angular/router';
import { AppRoutes } from './constants/app-routes';

export const routes: Routes = [
  {
    path: AppRoutes.HOME,
    loadComponent: () => import('./pages/home-page/home-page.component').then((c) => c.HomePageComponent),
  },
  {
    path: AppRoutes.BLOG_LIST,
    loadComponent: () => import('./pages/blog/blog-list/blog-list.component').then((c) => c.BlogListComponent),
  },

  {
    path: AppRoutes.BLOG,
    loadComponent: () => import('./pages/blog/blog-detail/blog-detail.component').then((c) => c.BlogDetailComponent),
  },

  {
    path: AppRoutes.SERVICE,
    loadComponent: () => import('./pages/service-page/service-page.component').then((c) => c.ServicePageComponent),
  },
  {
    path: '**',
    redirectTo: AppRoutes.HOME,
  }
];
